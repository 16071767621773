import React, { useEffect, useState } from "react";
import { useAddFormDataMutation, useGetFormDataQuery } from '../redux/api/form';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa' 

const FormModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const {
    data: FormDataResponse,
    isLoading: isincomesLoading,
    error: incomesError,
  } = useGetFormDataQuery();
  const [form, setForm] = useState([]);
  const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      if (FormDataResponse) {
        setForm(FormDataResponse.data);
        setLoading(false); 
      }
    }, [FormDataResponse]);
  
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      telephone: '',
      gender: '',
      investment_key1: '',
      investment_key2: '', 
      investment_key3: '' 
    });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [addFormData] = useAddFormDataMutation();
    const isLargeScreen = window.innerWidth >= 1024; 
  
  const rightMarginStyle = isLargeScreen ? { marginRight: '50px' } : {};
  const leftMarginStyle = isLargeScreen ? { marginLeft: '10px' } : {};
    const handleNext = () => {
      const newErrors = {};
  
      if (step === 1) {
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) {
          newErrors.email = 'Email is required';
        } else if (!formData.email.includes('@')) {
          newErrors.email = 'Email must contain "@"';
        }
        if (!formData.telephone) newErrors.telephone = 'Phone Number is required';
        if (!formData.gender) newErrors.gender = 'Gender is required';
      }
  
      if (step === 2) {
        if (!formData.investment_key1) newErrors.investment_key1 = 'Please choose one option';
      }
  
      if (step === 3) {
        if (!formData.investment_key2) newErrors.investment_key2 = 'Please choose one option';
      }
  
      if (step === 4) {
        if (!formData.investment_key3) newErrors.investment_key3 = 'Please choose one option';
      }
  
      if (step === 5) {
        if (!formData.termsAccepted) newErrors.termsAccepted = 'You must accept the terms and conditions';
      }
  
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setErrors({});
        setStep((prevStep) => Math.min(prevStep + 1, 5));
      }
    };
  
    const handlePrev = () => {
      setStep((prevStep) => Math.max(prevStep - 1, 1));
    };
  
    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const newErrors = {};
      if (!formData.termsAccepted) {
        newErrors.termsAccepted = 'You must accept the terms and conditions.';
      }
    
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return; 
      }
    
      console.log('Form Data:', formData);
      setSubmitted(true); // Set submitted to true
    
      try {
        await addFormData(formData).unwrap();
        resetForm();
      } catch (error) {
        console.error('Failed to submit form:', error);
      }
    };
    
    const resetForm = () => {
      setFormData({
        name: '',
        email: '',
        telephone: '',
        gender: '',
        investment_key1: '',
        termsAccepted: false,
        investment_key2: '',
        investment_key3: ''
      });
      setStep(1); 
    };

  return (
    <div
      className="fixed inset-0 py-5 flex items-center justify-center  bg-opacity-40 backdrop-blur-lg"
      onClick={onClose}
    >
      <div
        className="relative mx-4 px-8 py-6 w-[400px] max-w-full overflow-y-auto rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out"
        onClick={(e) => e.stopPropagation()}
      >


<div
    className="d-flex flex-column mx-auto mb-4 p-3 bg-white rounded shadow"
    style={{
        flex: '1 0 45%',
        width: '100%', 
        maxWidth: '600px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        margin: '20px auto', 
        ...rightMarginStyle 
    }}
>
          <div className="inner-column">
            <div className="form-boxed">
              <div className="consult-form">
                <form onSubmit={handleSubmit}>
                  {/* Step Indicator */}
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <div style={{ width: '20%', height: '5px', background: step > 1 ? '#4ab87f' : '#ddd' }}></div>
                    <div style={{ width: '20%', height: '5px', background: step > 2 ? '#4ab87f' : '#ddd' }}></div>
                    <div style={{ width: '20%', height: '5px', background: step > 3 ? '#4ab87f' : '#ddd' }}></div>
                    <div style={{ width: '20%', height: '5px', background: step > 4 ? '#4ab87f' : '#ddd' }}></div>
                    <div style={{ width: '20%', height: '5px', background: step >= 5 ? '#4ab87f' : '#ddd' }}></div>
                  <span style={{marginLeft:'30px',marginTop:'-10px'}}><FaTimes
    size={20}
    onClick={onClose}
    style={{color:'red'}}
  /></span>  
                  </div>

                  {/* Step 1 */}
                  {step === 1 && (
                    <div className="step">
                      <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', textAlign: 'start' }}>Investement Form</h2>
                      <h3 style={{ fontSize: '20px', color: '#666', textAlign: 'start', marginBottom: '20px' }}>Personal Info</h3>
                      
                      <div style={{ marginBottom: '20px' }}>
                        <label htmlFor="name" style={{ fontWeight: '500', color: '#333' }}>First and Last Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={formData.name}
                          onChange={handleChange}
                          style={{
                            width: '100%',
                            padding: '10px',
                            border: errors.name ? '1px solid red' : '1px solid #ddd',
                            borderRadius: '4px',
                            boxSizing: 'border-box'
                          }}
                        />
                        {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
                      </div>
                      
                      <div style={{ marginBottom: '20px' }}>
                        <label htmlFor="email" style={{ fontWeight: '500', color: '#333' }}>Email Address</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={formData.email}
                          onChange={handleChange}
                          style={{
                            width: '100%',
                            padding: '10px',
                            border: errors.email ? '1px solid red' : '1px solid #ddd',
                            borderRadius: '4px',
                            boxSizing: 'border-box'
                          }}
                        />
                        {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                      </div>
                      
                      <div style={{ marginBottom: '20px' }}>
                        <label htmlFor="telephone" style={{ fontWeight: '500', color: '#333' }}>Phone Number</label>
                        <input
                          type="text"
                          name="telephone"
                          id="telephone"
                          value={formData.telephone}
                          onChange={handleChange}
                          style={{
                            width: '100%',
                            padding: '10px',
                            border: errors.telephone ? '1px solid red' : '1px solid #ddd',
                            borderRadius: '4px',
                            boxSizing: 'border-box'
                          }}
                        />
                        {errors.telephone && <p style={{ color: 'red' }}>{errors.telephone}</p>}
                      </div>
                      
                      <label style={{ fontWeight: '500', color: '#333' }}>Gender</label>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginBottom: '20px', justifyContent: 'start' }}>
                        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                          Male
                          <input
                            type="radio"
                            name="gender"
                            value="Male"
                            checked={formData.gender === 'Male'}
                            onChange={handleChange}
                           style={{ marginLeft: '5px', accentColor: '#4ab87f' }}
                          />
                        </label>
                        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                          Female
                          <input
                            type="radio"
                            name="gender"
                            value="Female"
                            checked={formData.gender === 'Female'}
                            onChange={handleChange}
                           style={{ marginLeft: '5px', accentColor: '#4ab87f' }}
                          />
                        </label>
                        {errors.gender && <p style={{ color: 'red', textAlign: 'center' }}>{errors.gender}</p>}
                      </div>
                      
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button type="button" onClick={handleNext} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: '#4ab87f', color: '#fff', border: 'none', cursor: 'pointer' }}>
                          Next
                        </button>
                      </div>
                    </div>                  
                  )}

                  {/* Step 2 */}
                  {step === 2 && (
  <div style={{ padding: '10px', maxWidth: '600px', margin: '0 auto' }}>
    <h2 style={{ fontSize: '28px', fontWeight: 'bold', color: '#333', textAlign: 'center', marginBottom: '20px' }}>Market Downturn Scenario</h2>

    <div style={{ marginBottom: '20px', fontSize: '16px', color: '#555', lineHeight: '1.6' }}>
      <p>Imagine the stock market has just experienced a significant downturn, with major indices dropping by 20% in a month. You have investments worth $50,000 in your portfolio.</p>
      <label style={{ display: 'block', fontWeight: '500', color: '#333', marginBottom: '10px' }}>How would you respond to this situation?</label>

      <div style={{ paddingLeft: '10px' }}>
        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key1"
            value="Risk Tolerance"
            checked={formData.investment_key1 === 'Risk Tolerance'}
            onChange={handleChange}
            style={{ marginRight: '10px', accentColor: '#4ab87f' }}
          />
          Sell a Portion of Investments
        </label>

        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key1"
            value="Balanced Approach"
            checked={formData.investment_key1 === 'Balanced Approach'}
            onChange={handleChange}
            style={{ marginRight: '10px', accentColor: '#4ab87f' }}
          />
          Hold Steady
        </label>

        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key1"
            value="Growth Strategy"
            checked={formData.investment_key1 === 'Growth Strategy'}
            onChange={handleChange}
            style={{ marginRight: '10px', accentColor: '#4ab87f' }}
          />
          Buy More Investments
        </label>

        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key1"
            value="Diversify Portfolio"
            checked={formData.investment_key1 === 'Diversify Portfolio'}
            onChange={handleChange}
            style={{ marginRight: '10px', accentColor: '#4ab87f' }}
          />
          Diversify Your Portfolio
        </label>
        {errors.investment_key1 && <p style={{ color: 'red', marginTop: '10px', fontSize: '14px' }}>{errors.investment_key1}</p>}
      </div>
    </div>

    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
      <button
        type="button"
        onClick={handlePrev}
        style={{
          padding: '10px 20px',
          borderRadius: '5px',
          backgroundColor: '#888',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
      >
        Previous
      </button>
      <button
        type="button"
        onClick={handleNext}
        style={{
          padding: '10px 20px',
          borderRadius: '5px',
          backgroundColor: '#4ab87f',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
      >
        Next
      </button>
    </div>
  </div>
)}



                  {/* Step 3 - Additional Information */}
                  {step === 3 && (
  <div style={{ padding: '10px', maxWidth: '600px', margin: '0 auto' }}>
    <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', textAlign: 'center' }}>Investment Opportunity Evaluation</h2>
    <div style={{ marginBottom: '20px', fontSize: '16px', color: '#555', lineHeight: '1.6' }}>
      <h6>A new high-growth tech startup is offering shares at an initial public offering (IPO). The company has innovative products but operates in a highly competitive market with no guaranteed success.</h6>
      <label style={{ fontWeight: '500', color: '#333' }}>Would you invest in this IPO?</label>
      <div style={{ marginTop: '10px' }}>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key2"
            value="Invest a Small Percentage"
            checked={formData.investment_key2 === 'Invest a Small Percentage'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#4ab87f' }}
          />
          Invest a Small Percentage
        </label>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key2"
            value="Invest a Moderate Percentage"
            checked={formData.investment_key2 === 'Invest a Moderate Percentage'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#4ab87f' }}
          />
          Invest a Moderate Percentage
        </label>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key2"
            value="Invest a Large Percentage"
            checked={formData.investment_key2 === 'Invest a Large Percentage'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#4ab87f' }}
          />
          Invest a Large Percentage
        </label>
        <label style={{ display: 'block', cursor: 'pointer', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key2"
            value="Avoid Investing"
            checked={formData.investment_key2 === 'Avoid Investing'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#4ab87f' }}
          />
          Avoid Investing
        </label>
        {errors.investment_key2 && <p style={{ color: 'red', marginTop: '10px', fontSize: '14px' }}>{errors.investment_key2}</p>}
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <button type="button" onClick={handlePrev} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: 'gray', color: '#fff', border: 'none', cursor: 'pointer', fontWeight: 'bold' }}>
        Previous
      </button>
      <button type="button" onClick={handleNext} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: '#4ab87f', color: '#fff', border: 'none', cursor: 'pointer', fontWeight: 'bold' }}>
        Next
      </button>
    </div>
  </div>
)}

{step === 4 && (
  <div style={{ padding: '10px', maxWidth: '600px', margin: '0 auto' }}>
    <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', textAlign: 'center' }}>Long-Term Financial Goals</h2>
    <div style={{ marginBottom: '20px', fontSize: '16px', color: '#555', lineHeight: '1.6' }}>
      <h6>You have set a long-term financial goal to retire comfortably in 20 years. Currently, your investments are a mix of bonds and stocks.</h6>
      <label style={{ fontWeight: '500', color: '#333' }}>How would you adjust your investment strategy to achieve this goal?</label>
      <div style={{ marginTop: '10px' }}>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key3"
            value="Increase Bond Holdings"
            checked={formData.investment_key3 === 'Increase Bond Holdings'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#4ab87f' }}
          />
          Increase Bond Holdings
        </label>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key3"
            value="Maintain Current Allocation"
            checked={formData.investment_key3 === 'Maintain Current Allocation'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#4ab87f' }}
          />
          Maintain Current Allocation
        </label>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key3"
            value="Increase Stock Holdings"
            checked={formData.investment_key3 === 'Increase Stock Holdings'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#4ab87f' }}
          />
          Increase Stock Holdings
        </label>
        <label style={{ display: 'block', cursor: 'pointer', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            name="investment_key3"
            value="Explore Alternative Investments"
            checked={formData.investment_key3 === 'Explore Alternative Investments'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#4ab87f' }}
          />
          Explore Alternative Investments
        </label>
        {errors.investment_key3 && <p style={{ color: 'red', marginTop: '10px', fontSize: '14px' }}>{errors.investment_key3}</p>}
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <button type="button" onClick={handlePrev} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: 'gray', color: '#fff', border: 'none', cursor: 'pointer', fontWeight: 'bold' }}>
        Previous
      </button>
      <button type="button" onClick={handleNext} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: '#4ab87f', color: '#fff', border: 'none', cursor: 'pointer', fontWeight: 'bold' }}>
        Next
      </button>
    </div>
  </div>
)}


                  {/* Step 5 - Terms and Conditions */}
                  {step === 5 && (
                    <div className="step">
                      <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', textAlign: 'center' }}>Terms and Conditions</h2>
                      <div style={{ marginBottom: '20px' }}>
                        <label style={{ fontWeight: '500', color: '#333' }}>
                          <input
                            type="checkbox"
                            name="termsAccepted"
                            checked={formData.termsAccepted}
                            onChange={handleChange}
                            style={{ marginRight: '5px' }}
                          />
                          I accept the terms and conditions
                        </label>
                        {errors.termsAccepted && <p style={{ color: 'red' }}>{errors.termsAccepted}</p>}
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button type="button" onClick={handlePrev} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: 'gray', color: '#fff', border: 'none', cursor: 'pointer' }}>
                          Previous
                        </button>
                        <button type="submit" style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: '#4ab87f', color: '#fff', border: 'none', cursor: 'pointer' }}>
                          Submit
                        </button>

                        
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#F5F5F5' 
}}>
  {loading ? (
    <div
     >
    </div>
  ) : submitted && form.length > 0 ? (
    <div style={{
      textAlign: 'center',
      padding: '20px',
      borderRadius: '10px',
      backgroundColor: '#fff', 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' 
    }}>
      <h3 style={{
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        margin: '20px 0'
      }}>
        <span style={{
          fontWeight: 'bold',
          color: '#007bff' 
        }}>{form[form.length - 1].name}</span> You are a: {form[form.length - 1].type} Person. See what service you need.
      </h3>
      <button onClick={() => navigate('/service-detail', {
        state: {
          name: form[form.length - 1].name,
          type: form[form.length - 1].type,
        }
      })} style={{
        marginTop: '15px',
        padding: '10px 20px',
        fontSize: '1rem',
        color: '#fff',
        backgroundColor: '#28a745', 
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s'
      }}>
        See Service
      </button>
    </div>
  ) : null}
    
      </div>
    </div>
  );
};

export default FormModal;
