import React, { useState,useEffect,useRef } from 'react';
import Footer from '../../src/components/footer'
import logo from '../assets/logo.png';
import { useGetFAQByIdQuery } from "../redux/api/FAQ";
import {useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import MobileMenu from '../components/MobileMenu';

function FAQs (){
    const { id } = useParams();
    const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
  const [faqs, setFAQS] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data: FAQs} = useGetFAQByIdQuery(id);
  const [fontSize2, setFontSize2] = useState('18px');
  // Function to update font size based on window width
  const updateFontSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      setFontSize2('15px');
    } else {
      setFontSize2('25px') // Default font size
    }
  };
    useEffect(() => {
        if (FAQs?.data) {
            setFAQS(FAQs.data);
          setLoading(false);
        } else {
          setLoading(true); 
        }
      }, [FAQs]);
    const faqText = FAQs?.data?.faq?.text || '';

  return (
    <div className="page-wrapper">
      <header className="main-header style-three" >
    	
        <div className="header-upper">
        	<div className="auto-container">
            	<div className="inner-container clearfix">
                	
                	<div className="pull-left logo-box">
                    	<div className="logo"><Link to="/"><img src={logo} alt="" title="" width={150} height={100}/></Link></div>
                    </div>
					
                   	<div className="nav-outer pull-left clearfix">
					
						<nav className="main-menu navbar-expand-md">
							<div className="navbar-header">
								<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							</div>

							<div className="navbar-collapse show collapse clearfix" id="navbarSupportedContent">
								<ul className="navigation clearfix">
									<li><Link to="/">Home</Link></li>
									<li><Link to="/about" >About Us</Link></li>
									<li className="dropdown"><Link to="#">Service</Link>
										<ul>
											<li><Link to="/service">Service</Link></li>
											<li><Link to="/service-detail">Service Detail</Link></li>
										</ul>
									</li>
								
									<li className="dropdown"><Link to="#">Blog</Link>
										<ul className="from-right">
											<li><Link to="/blog">Our Blog</Link></li>
											<li><Link to="/blog-detail">Blog Detail</Link></li>
										</ul>
									</li>
                  <li className="current">
  <Link to="/faq" style={{ 
    position: 'relative', 
    color: '#42C596', 
    textDecoration: 'none' 
  }}>
    FAQ
    <span style={{
      position: 'absolute',
      left: 0,
      bottom: '30px',
      width: '100%',
      height: '2px',
      backgroundColor: '#42C596',
    }}></span>
  </Link>
</li>

									<li ><Link to="/contact">Contact</Link></li>
								</ul>
							</div>
							
						</nav>
            <MobileMenu/>
					</div>
                 
                </div>
            </div>
        </div>
    
		
    </header>
    <div className='text-center' style={{fontFamily:'Arial',fontSize:fontSize2,marginTop:'20px',cursor: 'pointer'}}>
    <Link
        to="/faq"
        style={{
          color: hoveredLink === 'beta' ? '#3da26f' : 'black', 
          textDecoration: 'none',
          fontFamily: 'Arial',
        }}
        onMouseEnter={() => setHoveredLink('beta')} 
        onMouseLeave={() => setHoveredLink(null)}  
      >
        Beta
      </Link>
      
      {" / "}
      <Link
        to={`/faq/children/${faqs?.breadcrumbs?.grand_parent_id}`}
        style={{
          color: hoveredLink === 'grand_parent' ? '#3da26f' : 'black', 
          textDecoration: 'none',
          fontFamily: 'Arial',
        }}
        onMouseEnter={() => setHoveredLink('grand_parent')} 
        onMouseLeave={() => setHoveredLink(null)} 
      >
        {faqs?.breadcrumbs?.grand_parent_title}
      </Link>
      
      {" / "}
 
      <Link
        to={`/faq/children/${faqs?.breadcrumbs?.grand_parent_id}`}
        style={{
          color: hoveredLink === 'parent' ? '#3da26f' : 'black', 
          textDecoration: 'none',
          fontFamily: 'Arial',
        }}
        onMouseEnter={() => setHoveredLink('parent')} 
        onMouseLeave={() => setHoveredLink(null)}  
      >
        {faqs?.breadcrumbs?.parent_title}
      </Link>
    </div>
    <div className="container mt-5 p-4 bg-white shadow rounded" style={{ fontFamily: 'Arial',cursor: 'pointer' }}>
      {loading ? ( 
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-8">
            <h2 className="font-weight-bold" style={{ color: 'black', fontSize: '30px', fontFamily: 'Arial', fontWeight: 'bold' }}>
              {FAQs?.data?.faq?.title}
            </h2>
            <div>
      <p
        style={{ color: 'black', fontFamily: 'Arial', marginTop: '5px' }}
        dangerouslySetInnerHTML={{ __html: faqText }}
      ></p>

<style>
        {`
          @media (max-width: 600px) { /* Adjust this value for your breakpoint */
            p iframe {
              width: 100%; /* Adjust as needed */
              max-width: 100%;
              height: auto;
            }
            p img {
              width: 50%; /* Adjust as needed */
              max-width: 50%;
              height: auto; /* Maintain aspect ratio */
            }
          }
        `}
      </style>
    </div>
          </div>

          <div className="col-md-4">
            <h2 className="font-weight-bold mb-4" style={{ color: 'black', fontSize: '30px', fontWeight: 'bold' }}>
              ⭐ Articles in this section
            </h2>
            <ul className="list-unstyled">
              {FAQs?.data?.faq_by_category?.map((faqItem, index) => (
                <li key={index}>
                  <Link
                    to={`/faq/${faqItem.faq_id}`}
                    className="d-block mb-2"
                    style={{
                      color: isHovered === faqItem.faq_id ? '#3da26f' : 'black',
                      textDecoration: 'none',
                      fontFamily: 'Arial',
                      fontSize: '20px'
                    }}
                    onMouseEnter={() => setIsHovered(faqItem.faq_id)}
                    onMouseLeave={() => setIsHovered(null)}
                  >
                    {faqItem.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
    <hr style={{marginTop:'50px'}}></hr>
      <Footer />
      <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
    </div>
  );
};

export default FAQs;
