import React, { useState ,useRef} from 'react';
import Footer from '../../src/components/footer'
import logo from '../assets/logo.png';
import News from '../../src/assets/news-4.jpg';
import News1 from '../../src/assets/news-5.jpg';
import News2 from '../../src/assets/news-6.jpg';
import News3 from '../../src/assets/news-7.jpg';
import News4 from '../../src/assets/news-8.jpg';
import { Link } from 'react-router-dom';
import MobileMenu from '../components/MobileMenu';

function Blog (){
	const [showScroll] = useState(false);
    const scrollLinkRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
 

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };


  return (
    <div className="page-wrapper">
     <header className="main-header style-three" >
    	
        <div className="header-upper">
        	<div className="auto-container">
            	<div className="inner-container clearfix">
                	
                	<div className="pull-left logo-box">
                    	<div className="logo"><Link to="/"><img src={logo} alt="" title="" width={150} height={100}/></Link></div>
                    </div>
					
                   	<div className="nav-outer pull-left clearfix">
					
						<nav className="main-menu navbar-expand-md">
							<div className="navbar-header">
								<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							</div>

							<div className="navbar-collapse show collapse clearfix" id="navbarSupportedContent">
								<ul className="navigation clearfix">
									<li><Link to="/">Home</Link></li>
									<li><Link to="/about" >About Us</Link></li>
									<li className="dropdown"><Link to="#">Service</Link>
										<ul>
											<li><Link to="/service">Service</Link></li>
											<li><Link to="/service-detail">Service Detail</Link></li>
										</ul>
									</li>
								
									<li className="current dropdown">
									<Link to="/blog" style={{ 
										position: 'relative', 
										color: '#42C596', 
										textDecoration: 'none' 
									}}>
										BLOG
										<span style={{
										position: 'absolute',
										left: 0,
										bottom: '30px',
										width: '100%',
										height: '2px',
										backgroundColor: '#42C596',
										}}></span>
									</Link>

										<ul className="from-right">
											<li><Link to="/blog">Our Blog</Link></li>
											<li><Link to="/blog-detail">Blog Detail</Link></li>
										</ul>
									</li>
                                    <li><Link to="/faq">FAQ</Link></li>
									<li ><Link to="/contact">Contact</Link></li>
								</ul>
							</div>
							
						</nav>
						<MobileMenu/>
					</div>
               
					
                </div>
            </div>
        </div>
    
		
    </header>


    <div class="page-title-section">
    	<div class="auto-container">
			<ul class="post-meta">
				<li><Link to="/">Home</Link></li>
				<li>Blog</li>
			</ul>
			<h2><span>Latest</span> From Our Press</h2>
		</div>
	</div>
	
    <div class="sidebar-page-container padding-top">
    	<div class="auto-container">
			<div class="row clearfix">
            	
               
                <div class="content-side col-lg-9 col-md-12 col-sm-12">
					<div class="our-blogs">
						
					
						<div class="news-block-three">
							<div class="inner-box">
								<div class="image">
									<Link to="/blog-detail"><img src={News} alt="" /></Link>
								</div>
								<div class="title">business</div>
								<h4><Link to="/blog-detail">Problems About Social Insurance For Truck Drivers</Link></h4>
								<div class="post-date">Decmber 14th, 2020 by <span>Admin</span></div>
							</div>
						</div>
						
						
						<div class="news-block-three">
							<div class="inner-box">
								<div class="image">
									<Link to="/blog-detail"><img src={News1} alt="" /></Link>
								</div>
								<div class="title">news</div>
								<h4><Link to="/blog-detail">Payment Online -Things That You Know To Protect Your Money Before Perform A Checkout</Link></h4>
								<div class="post-date">Decmber 14th, 2020 by <span>Admin</span></div>
							</div>
						</div>
						
					
						<div class="news-block-three">
							<div class="inner-box">
								<div class="image">
									<Link to="/blog-detail"><img src={News2} alt="" /></Link>
								</div>
								<div class="title">business</div>
								<h4><Link to="/blog-detail">5 Steps To Build Strategy Planning</Link></h4>
								<div class="post-date">Decmber 14th, 2020 by <span>Admin</span></div>
							</div>
						</div>
						
					
						<div class="news-block-three">
							<div class="inner-box">
								<div class="image">
									<Link to="/blog-detail"><img src={News3} alt="" /></Link>
								</div>
								<div class="title">tips & tricks</div>
								<h4><Link to="/blog-detail">5 Secrets To Coaching Your Employees To Greatness</Link></h4>
								<div class="post-date">Decmber 14th, 2020 by <span>Admin</span></div>
							</div>
						</div>
						
						
						<div class="news-block-three">
							<div class="inner-box">
								<div class="image">
									<Link to="/blog-detail"><img src={News4} alt="" /></Link>
								</div>
								<div class="title">News</div>
								<h4><Link to="/blog-detail">Trend Of Consumer Market 2020</Link></h4>
								<div class="post-date">Decmber 14th, 2020 by <span>Admin</span></div>
							</div>
						</div>
						
					</div>
					
				
					<div class="styled-pagination">
						<ul class="clearfix">
							<li><Link to="#">1</Link></li>
							<li class="active"><Link to="#">2</Link></li>
							<li><Link to="#">3</Link></li>
							<li><Link to="#">4</Link></li>
							<li class="next"><Link to="#"><span class="ti-angle-right"></span> </Link></li>
						</ul>
					</div>
					
				</div>
				
				
                <div class="sidebar-side col-lg-3 col-md-12 col-sm-12">
                	<aside class="sidebar sticky-top margin-left">
						
					
                        <div class="sidebar-widget search-box">
							<div class="sidebar-title">
                            	<h4>Search</h4>
                            </div>
                        	<form method="post" action="/contact">
                                <div class="form-group">
                                    <input type="search" name="search-field" value="" placeholder="Type & Hit Enter..." required/>
                                    <button type="submit"><span class="icon fa fa-search"></span></button>
                                </div>
                            </form>
						</div>
						
					
                        <div class="sidebar-widget categories-blog">
                        	<div class="sidebar-title">
                            	<h4>Categories</h4>
                            </div>
                            <ul>
								<li><Link to="#">All <span>25</span></Link></li>
								<li><Link to="#">News <span>12</span></Link></li>
								<li><Link to="#">business <span>7</span></Link></li>
								<li><Link to="#">tips & tricks <span>4</span></Link></li>
								<li><Link to="#">Others <span>2</span></Link></li>
							</ul>
                        </div>
						
						
                        <div class="sidebar-widget popular-posts">
							<div class="sidebar-title">
                            	<h4>Recent Posts</h4>
                            </div>
                            <div class="widget-content">
                                <div class="post">
                                    <figure class="post-thumb"><Link to="blog-single.html"><img src="images/resource/post-thumb-1.jpg" alt=""/></Link></figure>
                                    <div class="text"><Link to="blog-single.html">5 Secrets To Coaching Your Employees To Greatness</Link></div>
								</div>
                                
                                <div class="post">
                                    <figure class="post-thumb"><Link to="blog-single.html"><img src="images/resource/post-thumb-2.jpg" alt=""/></Link></figure>
                                    <div class="text"><Link to="blog-single.html">5 Steps To Build Strategy Planning</Link></div>
                                </div>
                                
                                <div class="post">
                                    <figure class="post-thumb"><Link to="blog-single.html"><img src="images/resource/post-thumb-3.jpg" alt=""/></Link></figure>
                                    <div class="text"><Link to="blog-single.html">Trend Of Consumer Market 2020</Link></div>
                                </div>
                            </div>
						</div>
						
                        <div class="sidebar-widget tags">
							<div class="sidebar-title">
                            	<h4>Tags</h4>
                            </div>
							<div class="widget-content">
								<Link to="#">Structure</Link>
								<Link to="#">Envato</Link>
								<Link to="#">Premium</Link>
								<Link to="#">Clean</Link>
								<Link to="#">WordPress</Link>
							</div>
						</div>
						
					</aside>
				</div>
				
			</div>
		</div>
	</div>
   


      <Footer />
	  <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
    </div>
  );
};

export default Blog;
