import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css/animate.css';
import '../css/animation.css';
import '../css/bootstrap.min.css';
import '../css/elegenticon.css';
import '../css/font-awesome.css';
import '../css/fontawesome.min.css';
import '../css/hover.css';
import '../css/icofont.min.css';
import '../css/jquery-ui.css';
import '../css/jquery.fancybox.min.css';
import '../css/jquery.mCustomScrollbar.min.css';
import '../css/linearicons.css';
import '../css/main.css';
import '../css/owl.css';
import '../css/responsive.css';
import '../css/simple-line-icons.css';
import '../css/themify-icons.css';
import logo from '../assets/logo.png';
import MobileMenu from '../components/MobileMenu';

export default function Header() {
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [blogDropdownOpen, setBlogDropdownOpen] = useState(false);
  const location = useLocation();

  const toggleServiceDropdown = () => {
    setServiceDropdownOpen(!serviceDropdownOpen);
  };

  const toggleBlogDropdown = () => {
    setBlogDropdownOpen(!blogDropdownOpen);
  };


  const underlineStyle = {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '30px',
    height: '2px',
    backgroundColor: '#4ab87f',
  };

  return (
    <>
      <header className="main-header">
        <br />
        <div className="header-upper">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <div className="pull-left logo-box">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="" title="" width={150} height={100} />
                  </Link>
                </div>
              </div>

              <div className="nav-outer pull-left clearfix">
                <nav className="main-menu navbar-expand-md">
                  <div className="navbar-header">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>

                  <div className="navbar-collapse show collapse clearfix" id="navbarSupportedContent">
                    <ul className="navigation clearfix">
                      <li style={{ position: 'relative' }}>
                      <Link to="/" style={{ color: location.pathname === '/' ? '#4ab87f' : 'black' }}>
                          Home
                        </Link>
                        {location.pathname === '/' && (
                          <span style={underlineStyle} />
                        )}
                      </li>
                      <li style={{ position: 'relative' }}>
                      <Link to="/about" style={{ color: location.pathname === '/about' ? '#4ab87f' : 'black' }}>About Us</Link>
                        {location.pathname === '/about' && (
                          <span style={underlineStyle} />
                        )}
                      </li>
                      <li className={`dropdown ${location.pathname === '/service' || location.pathname === '/service-detail' ? 'active' : ''}`} style={{ position: 'relative' }}>
                      <Link to="/service" style={{ color: location.pathname === '/service' ? '#4ab87f' : 'black' }}>Service</Link>
                        {location.pathname === '/service' && (
                          <span style={underlineStyle} />
                        )}
                        <ul>
                          <li><Link to="/service" style={{ color: location.pathname === '/service' ? '#4ab87f' : 'black' }}>Service</Link></li>
                          <li><Link to="/service-detail" style={{ color: location.pathname === '/service-detail' ? '#4ab87f' : 'black' }}>Service Detail</Link></li>
                        </ul>
                      </li>
                      <li className={`dropdown ${location.pathname === '/blog' || location.pathname === '/blog-detail' ? 'active' : ''}`} style={{ position: 'relative' }}>
                        <Link to="/blog" style={{ color: location.pathname === '/blog' ? '#4ab87f' : 'black' }}>Blog</Link>
                        {location.pathname === '/blog' && (
                          <span style={underlineStyle} />
                        )}
                        <ul className="from-right">
                          <li><Link to="/blog" style={{ color: location.pathname === '/blog' ? '#4ab87f' : 'black' }}>Our Blog</Link></li>
                          <li><Link to="/blog-detail" style={{ color: location.pathname === '/blog-detail' ? '#4ab87f' : 'black' }}>Blog Detail</Link></li>
                        </ul>
                      </li>
                      <li style={{ position: 'relative' }}>
                      <Link to="/faq" style={{ color: location.pathname === '/faq' ? '#4ab87f' : 'black' }}>FAQ</Link>
                        {location.pathname === '/faq' && (
                          <span style={underlineStyle} />
                        )}
                      </li>
                      <li style={{ position: 'relative' }}>
                      <Link to="/contact" style={{ color: location.pathname === '/contact' ? '#4ab87f' : 'black' }}>Contact</Link>
                        {location.pathname === '/contact' && (
                          <span style={underlineStyle} />
                        )}
                      </li>
                    </ul>
                  </div>
                </nav>
                <MobileMenu />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
