import React, { useState } from 'react';
import Footer from '../../src/components/footer'
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import MobileMenu from '../components/MobileMenu';

function Contact (){
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };
  
  return (
    <div className="page-wrapper">
     <header className="main-header style-three" >
    	
      <div className="header-upper">
        <div className="auto-container">
            <div className="inner-container clearfix">
                
                <div className="pull-left logo-box">
                    <div className="logo"><Link to="/"><img src={logo} alt="" title="" width={150} height={100}/></Link></div>
                  </div>
        
                   <div className="nav-outer pull-left clearfix">
        
          <nav className="main-menu navbar-expand-md">
            <div className="navbar-header">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>

            <div className="navbar-collapse show collapse clearfix" id="navbarSupportedContent">
              <ul className="navigation clearfix">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about" >About Us</Link></li>
                <li className="dropdown"><Link to="#">Service</Link>
                  <ul>
                    <li><Link to="/service">Service</Link></li>
                    <li><Link to="/service-detail">Service Detail</Link></li>
                  </ul>
                </li>
              
                <li className="dropdown"><Link to="#">Blog</Link>
                  <ul className="from-right">
                    <li><Link to="/blog">Our Blog</Link></li>
                    <li><Link to="/blog-detail">Blog Detail</Link></li>
                  </ul>
                </li>
                                  <li><Link to="/faq">FAQ</Link></li>
                                  <li className="current">
  <Link to="/contact" style={{ 
    position: 'relative', 
    color: '#42C596', 
    textDecoration: 'none' 
  }}>
    CONTACT
    <span style={{
      position: 'absolute',
      left: 0,
      bottom: '30px',
      width: '100%',
      height: '2px',
      backgroundColor: '#42C596',
    }}></span>
  </Link>
</li>
              </ul>
            </div>
            
          </nav>
          <MobileMenu/>
        </div>
               
       
              </div>
          </div>
      </div>
   
    
  
  </header>


      <div className="map-section">
        <div className="contact-map-area">
          <iframe
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26496.135822635544!2d35.48368275677567!3d33.889216585979106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17215880a78f%3A0x729182bae99836b4!2sBeirut!5e0!3m2!1sen!2slb!4v1726905426482!5m2!1sen!2sl"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>

      <div className="container " style={{ fontFamily: 'Arial, Helvetica, Roboto, sans-serif',fontWeight:'bold',color:'black' }}>
  <div className="row">
    {/* Left Section with Contact Details */}
    <div className="mb-4 py-5 mt-5 text-center" style={{ width: '450px', margin: 'auto' }}>
  <div className="bg-light p-3 rounded shadow-lg">
    <h3>Contact Info</h3>
    <ul className="list-unstyled mt-3" style={{ fontSize: '20px' }}>
      <li className="mb-3">
      <Link to="tel:+96176157762" className="text-black hover:text-blue-900" target='_blank'>
  <i className="icofont-phone" style={{ color: 'blue' }}></i> +961 76 157 762
</Link>

      </li>
      <li className="mb-3">
        <Link to="https://wa.me/96176157762" style={{ color: 'inherit', textDecoration: 'none' }} className="text-black no-underline hover:text-blue-900"  target='_blank'> 
          <i className="icofont-whatsapp" style={{ color: 'green' }}></i> +961 76 157 762
        </Link>
      </li>
      <li>
        <Link to="https://goo.gl/maps/your-location-link" target="_blank" style={{ color: 'inherit', textDecoration: 'none' }} className="text-black no-underline hover:text-blue-900">
          <i className="icofont-google-map" style={{ color: 'red' }}></i> Beirut, Lebanon
        </Link>
      </li>
    </ul>
  </div>
</div>


    {/* Right Section with Contact Form */}
    <div className="col-md-7">
      <form onSubmit={handleSubmit} className="bg-white shadow-lg rounded p-4">
        <h2 className="text-center mb-4">Contact Us</h2>

        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder='Enter Your Name'
            className="form-control"
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder='Enter Your Email'
            value={formData.email}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            className="form-control"
            placeholder='Leave a Message'
            required
          ></textarea>
        </div>

        <button
  type="submit"
  style={{
    backgroundColor: '#002147',
    color: 'white',
    width: '100%',
    padding: '10px',
    fontSize:'17px',
    border: 'none',
    cursor: 'pointer',
  }}
  onMouseEnter={(e) => (e.target.style.backgroundColor = 'blue')}
  onMouseLeave={(e) => (e.target.style.backgroundColor = 'navy')}
>
  Send Message
</button>

      </form>
    </div>
  </div>
</div>


      <Footer />
    </div>
  );
};

export default Contact;
