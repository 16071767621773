import React, { useState,useEffect } from 'react';
import Footer from '../../src/components/footer'
import logo from '../assets/logo.png';
import { useGetSubCategoryFAQsQuery } from "../redux/api//FAQ";
import {useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import MobileMenu from '../components/MobileMenu';

function FAQSubCategory (){
    const { id } = useParams();
  const [subcategories, setSubCategories] = useState([]);
  const [fontSize, setFontSize] = useState('16px'); // Default font size
  const [fontSize2, setFontSize2] = useState('18px');
  const [fontSize3, setFontSize3] = useState('30px');
  // Function to update font size based on window width
  const updateFontSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      setFontSize('14px'); 
      setFontSize2('15px');
      setFontSize3('20px');// Small screen size
    } else {
      setFontSize('18px');
      setFontSize2('25px');
    }
  };

  useEffect(() => {
    updateFontSize(); // Set initial font size
    window.addEventListener('resize', updateFontSize); // Update on resize

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', updateFontSize);
    };
  }, []);

  const [openIndex, setOpenIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };


    

  const { data: SubCategoriesResponse } = useGetSubCategoryFAQsQuery(id);
  useEffect(() => {
    if (SubCategoriesResponse?.data) {
      setSubCategories(SubCategoriesResponse.data);
      setLoading(false); 
    } else {
      setLoading(true); 
    }
  }, [SubCategoriesResponse]);
  
  return (
    <div className="page-wrapper" >
      <header className="main-header style-three">
    	
        <div className="header-upper">
        	<div className="auto-container">
            	<div className="inner-container clearfix">
                	
                	<div className="pull-left logo-box">
                    	<div className="logo"><Link to="/"><img src={logo} alt="" title="" width={150} height={100}/></Link></div>
                    </div>
					
                   	<div className="nav-outer pull-left clearfix">
					
						<nav className="main-menu navbar-expand-md">
							<div className="navbar-header">
								<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							</div>

							<div className="navbar-collapse show collapse clearfix" id="navbarSupportedContent">
								<ul className="navigation clearfix">
									<li><Link to="/">Home</Link></li>
									<li><Link to="/about" >About Us</Link></li>
									<li className="dropdown"><Link to="#">Service</Link>
										<ul>
											<li><Link to="/service">Service</Link></li>
											<li><Link to="/service-detail">Service Detail</Link></li>
										</ul>
									</li>
								
									<li className="dropdown"><Link to="#">Blog</Link>
										<ul className="from-right">
											<li><Link to="/blog">Our Blog</Link></li>
											<li><Link to="/blog-detail">Blog Detail</Link></li>
										</ul>
									</li>
                  <li className="current">
  <Link to="/faq" style={{ 
    position: 'relative', 
    color: '#42C596', 
    textDecoration: 'none' 
  }}>
    FAQ
    <span style={{
      position: 'absolute',
      left: 0,
      bottom: '30px',
      width: '100%',
      height: '2px',
      backgroundColor: '#42C596',
    }}></span>
  </Link>
</li>
									<li ><Link to="/contact">Contact</Link></li>
								</ul>
							</div>
							
						</nav>
            <MobileMenu/>
					</div>
                 
     
                </div>
            </div>
        </div>
    
		
    </header>
    <div className='text-center' style={{fontFamily:'Arial', fontSize: fontSize2,marginTop:'20px',cursor: 'pointer'}}>
    <Link
        to="/faq"
        style={{
          color: hoveredLink === 'beta' ? '#3da26f' : 'black', 
          textDecoration: 'none',
          fontFamily: 'Arial',
        }}
        onMouseEnter={() => setHoveredLink('beta')} 
        onMouseLeave={() => setHoveredLink(null)}  
      >
        Beta
      </Link>
      
      {" / "}
      <Link
        to={`/faq/children/${id}`}
        style={{
          color: hoveredLink === 'grand_parent' ? '#3da26f' : 'black', 
          textDecoration: 'none',
          fontFamily: 'Arial',
        }}
        onMouseEnter={() => setHoveredLink('grand_parent')} 
        onMouseLeave={() => setHoveredLink(null)} 
      >
        {SubCategoriesResponse?.category?.title}
      </Link>
    </div>
    <div className="container mt-3" style={{ transition: 'all 1s ease-in-out' }}>
      {loading ? ( 
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <h3 style={{ fontWeight: 'bold', fontFamily: 'Arial', color: 'black', fontSize: fontSize3 ,cursor: 'pointer'}}>
            {SubCategoriesResponse?.category?.title}
          </h3>
          <p style={{ fontFamily: 'Arial', color: 'black', fontSize: fontSize3,cursor: 'pointer'}}>
            {SubCategoriesResponse?.category?.subtitle}
          </p>

          {subcategories?.map((subcategory, index) => (
  <div key={index} className="mb-3">
    <div
  style={{
    border: '1px solid #e1e1e1',
    borderRadius: '10px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    transition: 'all 0.5s ease-in-out'
  }}
>
      <h2 style={{ marginBottom: '0' }}>
      <button
      className="accordion-button d-flex justify-content-between align-items-center"
      type="button"
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        fontWeight: 'bold',
        color: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 15px',
        fontSize: fontSize, // Dynamically set font size
      }}
      onClick={() => toggleFaq(index)}
      aria-expanded={openIndex === index ? 'true' : 'false'}
      aria-controls={`collapse-${index}`}
    >
          <span style={{ flexGrow: 1, textAlign: 'left' }}>{subcategory.title}</span>
          <span
            style={{
              transition: 'transform 0.5s ease',
              transform: openIndex === index ? 'rotate(0deg)' : 'rotate(180deg)',
              marginLeft: 'auto',
              fontSize: '1.2rem',
            }}
          >
            ⌃
          </span>
        </button>
      </h2>
      <div
  id={`collapse-${index}`}
  className={`accordion-collapse collapse ${openIndex === index ? 'show' : ''}`}
  style={{
    marginTop: '3px',
    padding: openIndex === index ? '3px 5px' : '0',
    borderTop: openIndex === index ? '1px solid #e1e1e1' : 'none',
    overflow: 'hidden',
    maxHeight: openIndex === index ? '400px' : '0', // Adjust this as needed
    transition: 'max-height 0.3s ease, padding 0.3s ease', // Transition for maxHeight and padding
  }}
>
  <div
    className="accordion-body"
    style={{
      fontSize: '17px',
      fontWeight: '600',
      color: '#003d24',
      marginLeft: '8px',
      marginTop: '7px',
      opacity: openIndex === index ? 1 : 0,
      height: openIndex === index ? 'auto' : '0', // This is optional, but can help with layout
      transition: 'opacity 0.3s ease', // Transition for opacity only
      overflow: openIndex === index ? 'visible' : 'hidden',
      padding: openIndex === index ? '10px 0' : '0', // Adjust padding for body
    }}
  >
          <h4 style={{ fontSize: '17px', fontWeight: '600', color: '#003d24', marginBottom: '8px' }}>
            FAQs:
          </h4>
          {subcategory.faqs.map((faq) => (
            <h5
              key={faq.faq_id}
              style={{
                fontSize: '17px',
                marginBottom: '3px',
                color: '#333333',
                fontWeight: '400',
              }}
            >
              <Link
                to={`/faq/${faq.faq_id}`}
                style={{
                  color: isHovered === faq.faq_id ? '#3da26f' : 'black',
                  textDecoration: 'none',
                }}
                onMouseEnter={() => setIsHovered(faq.faq_id)}
                onMouseLeave={() => setIsHovered(null)}
              >
                {faq.title}
              </Link>
            </h5>
          ))}
        </div>
      </div>
    </div>
  </div>
))}

        </>
      )}
    </div>

<hr style={{marginTop:'50px'}}></hr>

      <Footer />
    </div>
  );
};

export default FAQSubCategory;
