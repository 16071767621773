

import Footer from '../../src/components/footer'
import React, { useEffect ,useState,useRef} from 'react';
import logo from '../assets/logo.png';
import { Carousel } from 'react-bootstrap';
import Background from '../../src/assets/background (2).jpg';
import Test from '../../src/assets/test.png';
import HomeAbout from '../../src/assets/home-about.png';
import Team1 from '../../src/assets/team-1.jpg';
import Team2 from '../../src/assets/team-2.jpg';
import Team3 from '../../src/assets/team-3.jpg';
import T1 from '../../src/assets/t1.jpg';
import T2 from '../../src/assets/t2.jpg';
import T3 from '../../src/assets/t3.jpg';
import { Link } from 'react-router-dom';
import MobileMenu from '../components/MobileMenu';
import Index from '../components/index';


function About() {
    const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 768);
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  return (
    <>

<div className="page-wrapper">
 	
<header className="main-header style-three" >
    	
        <div className="header-upper">
        	<div className="auto-container">
            	<div className="inner-container clearfix">
                	
                	<div className="pull-left logo-box">
                    	<div className="logo"><Link to="/"><img src={logo} alt="" title="" width={150} height={100}/></Link></div>
                    </div>
					
                   	<div className="nav-outer pull-left clearfix">
					
						<nav className="main-menu navbar-expand-md">
							<div className="navbar-header">
								<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							</div>

							<div className="navbar-collapse show collapse clearfix" id="navbarSupportedContent">
								<ul className="navigation clearfix">
									<li><Link to="/">Home</Link></li>
                                    <li className="current">
  <Link to="/about" style={{ 
    position: 'relative', 
    color: '#42C596', 
    textDecoration: 'none' 
  }}>
    ABOUT US
    <span style={{
      position: 'absolute',
      left: 0,
      bottom: '30px',
      width: '100%',
      height: '2px',
      backgroundColor: '#42C596',
    }}></span>
  </Link>
</li>
									<li className="dropdown"><Link to="#">Service</Link>
										<ul>
											<li><Link to="/service">Service</Link></li>
											<li><Link to="/service-detail">Service Detail</Link></li>
										</ul>
									</li>
								
									<li className="dropdown"><Link to="#">Blog</Link>
										<ul className="from-right">
											<li><Link to="/blog">Our Blog</Link></li>
											<li><Link to="/blog-detail">Blog Detail</Link></li>
										</ul>
									</li>
                                    <li><Link to="/faq">FAQ</Link></li>
									<li ><Link to="/contact">Contact</Link></li>
								</ul>
							</div>
							
						</nav>
                        <MobileMenu/>
					</div>
                 
			
                </div>
            </div>
        </div>
		
    </header>
    <img src={Background} alt="Background" style={{ width: '100%', maxHeight: '700px', height: 'auto' }} />
    
	<div style={{ padding: '40px 20px', maxWidth: '1200px', margin: '40px auto', fontFamily: 'Arial, sans-serif', color: '#333', backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', lineHeight: '1.6' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '30px' }}>
        <div style={{ flex: isSmallScreen ? '0 0 100%' : '0 0 48%', marginRight: isSmallScreen ? '0' : '2%', boxSizing: 'border-box', marginBottom: isSmallScreen ? '20px' : '0' }}>
          <h2 style={{ textAlign: 'center', color: '#2c3e50', fontSize: '2.5rem', marginBottom: '20px', borderBottom: '3px solid #2c3e50', paddingBottom: '10px', letterSpacing: '1px' }}>Our Story</h2>
          <img 
            src={T1} 
            alt="Our Story" 
            style={{ float: 'left', marginRight: '15px', width: '180px', height: 'auto', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)' }} 
          />
          <p style={{ fontSize: '1.1rem', textAlign: 'justify' }}>
            Founded in 2022, Beta was built on the belief that successful investing is more than just numbers—it’s about trust, foresight, and personalized service. Over the years, we have grown into a leading investment fund, trusted by clients across the globe for our innovative strategies and client-focused approach. Our mission is to provide tailored investment solutions that help individuals and institutions build long-term wealth.
          </p>
        </div>

        <div style={{ flex: isSmallScreen ? '0 0 100%' : '0 0 48%', boxSizing: 'border-box', marginBottom: isSmallScreen ? '20px' : '0' }}>
          <h2 style={{ textAlign: 'center', color: '#2c3e50', fontSize: '2.5rem', marginBottom: '20px', borderBottom: '3px solid #2c3e50', paddingBottom: '10px', letterSpacing: '1px' }}>Our Philosophy</h2>
          <img 
            src={T1} 
            alt="Our Philosophy" 
            style={{ float: 'left', marginRight: '15px', width: '180px', height: 'auto', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)' }} 
          />
          <p style={{ fontSize: '1.1rem', textAlign: 'justify' }}>
            We believe in a disciplined, research-driven approach to investing. Our goal is to create diversified portfolios that minimize risk while maximizing returns. We identify growth opportunities across multiple sectors and regions through extensive market research to ensure our clients benefit from the best possible returns.
          </p>
        </div>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '30px' }}>
        <div style={{ flex: isSmallScreen ? '0 0 100%' : '0 0 48%', marginRight: isSmallScreen ? '0' : '2%', boxSizing: 'border-box', marginBottom: isSmallScreen ? '20px' : '0' }}>
          <h2 style={{ textAlign: 'center', color: '#2c3e50', fontSize: '2.5rem', marginBottom: '20px', borderBottom: '3px solid #2c3e50', paddingBottom: '10px', letterSpacing: '1px' }}>Our Team</h2>
          <img 
            src={T1} 
            alt="Our Team" 
            style={{ float: 'left', marginRight: '15px', width: '180px', height: 'auto', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)' }} 
          />
          <p style={{ fontSize: '1.1rem', textAlign: 'justify' }}>
            At the heart of Beta is a diverse team of seasoned financial experts, analysts, and portfolio managers. Each member brings a wealth of experience from top financial institutions and a shared commitment to helping clients achieve their financial goals.
          </p>
        </div>

        <div style={{ flex: isSmallScreen ? '0 0 100%' : '0 0 48%', boxSizing: 'border-box', marginBottom: isSmallScreen ? '20px' : '0' }}>
          <h2 style={{ textAlign: 'center', color: '#2c3e50', fontSize: '2.5rem', marginBottom: '20px', borderBottom: '3px solid #2c3e50', paddingBottom: '10px', letterSpacing: '1px' }}>Our CEO</h2>
          <img 
            src={T1} 
            alt="Our CEO" 
            style={{ float: 'left', marginRight: '15px', width: '180px', height: 'auto', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)' }} 
          />
          <p style={{ fontSize: '1.1rem', textAlign: 'justify' }}>
            Raoul Aoun, Founder and CEO of Beta Investment Group, is a visionary leader with years of experience in the world of finance and investment. From humble beginnings as a day trader to managing family and friends' capital, to now leading a successful hedge fund and launching a bespoke mutual fund—Raoul has transformed passion into a powerhouse of wealth creation. Under their leadership, Beta has grown into a trusted partner for wealth management, known for personalized service, market expertise, and a commitment to client success. Passionate about helping clients achieve long-term financial security, Raoul continues to drive innovation and excellence at the helm of the firm.
          </p>
        </div>
      </div>
    </div>
    < Index/>
    {/* <div className="reputation-section-two style-two">
                    <div className="auto-container">
                       <div className="row clearfix">


                            <div className="form-column col-lg-5 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="form-boxed">
                                        <h5>free consulation</h5>

                                        <div className="consult-form">
                                            <form method="post" action="donate.html">


                                                <div className="form-group">
                                                    <label>full name</label>
                                                    <input type="text" name="name" placeholder="Enter Your Full Name" required />
                                                </div>

                                                <div className="form-group">
                                                    <label>phone number</label>
                                                    <input type="text" name="phone" placeholder="Enter your phone number" required />
                                                </div>

                                                <div className="form-group">
                                                    <label>Solutions (dropdown):</label>
                                                    <select className="custom-select-box">
                                                        <option>Choose topic</option>
                                                        <option>Portfolio Management</option>
                                                        <option>Investment advisory
                                                        </option>
                                                        <option>Wealth Planning</option>
                                                        <option>Corporate financial crisis management</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label>message</label>
                                                    <textarea name="message" placeholder="Write your message here"></textarea>
                                                </div>
                                                <div className="form-group">
                                                    <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="txt">send your messenger</span></button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="content-column col-lg-7 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="sec-title">
                                        <div className="title">why choose us?</div>
                                        <h2>Where Expertise Meets Exceptional Growth</h2>
                                    </div>
                                    <div className="blocks-outer">


                                        <div className="reputation-block">
                                            <div className="inner-box">
                                                <h5>Expertise</h5>
                                                <div className="text">Our team brings decades of combined experience in managing high-value portfolios.                                                .</div>
                                            </div>
                                        </div>


                                        <div className="reputation-block">
                                            <div className="inner-box">
                                                <h5>Personalized Service</h5>
                                                <div className="text">We tailor our investment strategies to fit your specific goals and risk tolerance</div>
                                            </div>
                                        </div>


                                        <div className="reputation-block">
                                            <div className="inner-box">
                                                <h5>Proven Results</h5>
                                                <div className="text">With a strong track record of outperforming the market, we consistently deliver returns for our clients.</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}
                
	
                <div className="experts-section" style={{ padding: '20px' }}>
  <div className="auto-container" style={{ maxWidth: '1200px', margin: '0 auto' }}>
    <div className="sec-title">
      <h2 style={{ marginBottom: '20px' }}>Letter from the CEO</h2>
    </div>

    <div className="row clearfix" style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center' }}>
      <div className="team-block" style={{ flex: '1', display: 'flex', alignItems: 'center', marginBottom: isSmallScreen ? '20px' : '0' }}>
        <div className="inner-box" style={{ flex: '1', display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: isSmallScreen ? 'center' : 'flex-start' }}>
          <div className="image" style={{ marginRight: isSmallScreen ? '0' : '20px', marginBottom: isSmallScreen ? '10px' : '0' }}>
            <Link to="#">
              <img src={Team1} alt="CEO" style={{ width: '100%' }} />
            </Link>
            <ul className="social-box" style={{ display: 'flex', gap: '6px', padding: '4px', listStyle: 'none', width: '200px' }}>
              {['facebook', 'twitter', 'instagram', 'whatsapp'].map((platform, index) => (
                <li key={index}>
                  <Link
                    to={`https://${platform}.com/`}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '15px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      backgroundColor: '#f9f9f9',
                      transition: 'background-color 0.3s ease, border-color 0.3s ease',
                      textDecoration: 'none',
                      color: 'inherit',
                      position: 'relative',
                    }}
                    aria-label={platform}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = platform === 'facebook' ? '#1877f2' : platform === 'twitter' ? '#1da1f2' : platform === 'instagram' ? '#dd2a7b' : '#25d366';
                      e.currentTarget.style.color = 'white';
                      e.currentTarget.style.borderColor = e.currentTarget.style.backgroundColor;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = '#f9f9f9';
                      e.currentTarget.style.color = 'inherit';
                      e.currentTarget.style.borderColor = '#ccc';
                    }}
                  >
                    <i className={`icofont-${platform}`} style={{ fontSize: '16px' }}></i>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div style={{ flex: '1', marginLeft: isSmallScreen ? '0' : '20px', color: 'black', textAlign: isSmallScreen ? 'center' : 'left' }}>
            <p style={{ fontSize: '17px', lineHeight: '1.6' }}>
              Dear Valued Stakeholders, <br /><br />
              As we reflect on our journey at Beta Investment Group, I am filled with a profound sense of gratitude and optimism. 
              Since our establishment in 2020, our commitment has been to navigate the complex financial landscape and provide tailored 
              solutions that empower the Lebanese people. <br /><br />
              In a time marked by economic challenges, we have remained steadfast in our mission. Our team has worked diligently to 
              adapt our strategies, ensuring that we not only meet the needs of our clients but also anticipate future trends in the market. 
              We believe in the power of innovation and collaboration... <br /><br />
              Sincerely,<br />
              Raoul Aoun,<br />
              CEO, Beta Investment Group
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

	<section
  style={{
    backgroundColor: '#4ab87f',
    color: 'white',
    padding: '10px', // Reduced padding
  }}
  className="testimonial-section"
>
  <div className="auto-container">
    <div className="row mb-3"> {/* Reduced margin-bottom */}
      <div
        className="col-md-5 d-flex flex-column align-items-center text-center"
        style={{ marginBottom: '1em' }} // Reduced margin-bottom
      >
        <br />
        <img
          src={Test}
          alt="Client Image"
          style={{
            width: '150px',
            marginBottom: '10px', // Reduced margin-bottom
          }}
        />
        <h2
          className="display-4"
          style={{ fontSize: '2rem', marginBottom: '5px' }} // Reduced font size and margin
        >
          4.5
        </h2>
        <div
          className="d-flex justify-content-center mb-2" // Reduced margin-bottom
          style={{ fontSize: '1.5rem' }}
        >
          {[...Array(4)].map((_, i) => (
            <span key={i} style={{ color: '#ffc107', marginRight: '3px' }}> {/* Reduced margin-right */}
              &#9733;
            </span>
          ))}
          <span
            className="half-star"
            style={{ position: 'relative', display: 'inline-block', marginRight: '3px' }} // Reduced margin-right
          >
            <span style={{ color: '#ffc107' }}>&#9733;</span>
            <span
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                overflow: 'hidden',
                width: '50%',
                color: '#6c757d',
              }}
            >
              &#9733;
            </span>
          </span>
        </div>
        <h5 style={{ fontWeight: 'bold', fontSize: '1rem' }}> {/* Reduced font size */}
          Client Success: Highlight how your firm helped clients
        </h5>
        <p style={{ maxWidth: '80%', fontSize: '0.8rem', lineHeight: '1.4rem' }}> {/* Reduced font size and line-height */}
          Full range of consultancy & training for data consultation strategic ways for business.
        </p>
      </div>

      <div className="col-md-1 d-flex justify-content-center">
        <div
          style={{
            width: '1px',
            height: '100%',
            backgroundColor: '#6c757d',
            margin: 'auto',
          }}
        ></div>
      </div>

      <div className="col-md-6">
		  <br/>
		  <br/>
      <Carousel controls={false} indicators={true}>
                                    <Carousel.Item>
                                        <div className="d-flex flex-column justify-content-center text-center">
                                            <h5
                                                style={{
                                                    textTransform: 'uppercase',
                                                    color: '#6c757d',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                Client Stories
                                            </h5>
                                            <h2 style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
                                                Achieving Financial Goals Together
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '15px',
                                                    fontSize: '0.9rem',
                                                    lineHeight: '1.5rem',
                                                }}
                                            >
                                                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
                                            </p>
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <img
                                                    src={T1}
                                                    alt="Author Image"
                                                    style={{
                                                        borderRadius: '50%', marginRight: '15px', width: '70px',
                                                        height: '70px',
                                                    }}
                                                />
                                                <div>
                                                    <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem' }}>
                                                        Jesonal Jelins
                                                    </h6>
                                                    <span style={{ color: '#6c757d', fontSize: '0.85rem' }}>
                                                        Team Captain
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>

                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>
                                            </div>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className="d-flex flex-column justify-content-center text-center">
                                            <h5
                                                style={{
                                                    textTransform: 'uppercase',
                                                    color: '#6c757d',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                Client Stories
                                            </h5>
                                            <h2 style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
                                                Empowering Clients for Success
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '15px',
                                                    fontSize: '0.9rem',
                                                    lineHeight: '1.5rem',
                                                }}
                                            >
                                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                            </p>
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <img
                                                    src={T2}
                                                    alt="Author Image"
                                                    style={{
                                                        borderRadius: '50%', marginRight: '15px', width: '70px',
                                                        height: '70px',
                                                    }}
                                                />
                                                <div>
                                                    <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem' }}>
                                                        Anna Smith
                                                    </h6>
                                                    <span style={{ color: '#6c757d', fontSize: '0.85rem' }}>
                                                        Project Lead
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>

                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>
                                            </div>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className="d-flex flex-column justify-content-center text-center">
                                            <h5
                                                style={{
                                                    textTransform: 'uppercase',
                                                    color: '#6c757d',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                Client Stories
                                            </h5>
                                            <h2 style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
                                                Building Long-Term Financial Security
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '15px',
                                                    fontSize: '0.9rem',
                                                    lineHeight: '1.5rem',
                                                }}
                                            >
                                                The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum".
                                            </p>
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <img
                                                    src={T3}
                                                    alt="Author Image"
                                                    style={{
                                                        borderRadius: '50%', marginRight: '15px', width: '70px',
                                                        height: '70px',
                                                    }}
                                                />
                                                <div>
                                                    <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem' }}>
                                                        Mark Johnson
                                                    </h6>
                                                    <span style={{ color: '#6c757d', fontSize: '0.85rem' }}>
                                                        Senior Consultant
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>

                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
      </div>
    </div>
  </div>
</section>
	
	
    <Footer/>
	
</div>

<div className="search-popup">
	<button className="close-search style-two"><span className="icofont-brand-nexus"></span></button>
	<button className="close-search"><span className="icofont-arrow-up"></span></button>
	<form method="post" action="blog.html">
		<div className="form-group">
			<input type="search" name="search-field" placeholder="Search Here" required=""/>
			<button type="submit"><i className="fa fa-search"></i></button>
		</div>
	</form>
</div>

<div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>


   </>
  );
}

export default About;
